import React from 'react';
import './PrivacyPolicy.css';
import {content} from './privacyPolicyContent';
import {Helmet} from 'react-helmet-async';

function PrivacyPolicy(props) {
    return (
        <div className="pp-main">
            <Helmet>
                <meta property="og:title" content="Meso Produce" />
                <meta
                    name="description"
                    content=" Thank you for choosing to be part of our community at Meso Produce, Inc."
                />
                <meta
                    property="og:description"
                    content=" Thank you for choosing to be part of our community at Meso Produce, Inc."
                />
            </Helmet>
            <h1>Privacy Notice</h1>
            <h5>Last updated August 20, 2021</h5>
            <div>
                <p>
                    Thank you for choosing to be part of our community at Meso
                    Produce, Inc. ("<span>Company</span>," "<span>we</span>," "
                    <span>us</span>," or "<span>our</span>"). We are committed
                    to protecting your personal information and your right to
                    privacy. If you have any questions or concerns about this
                    privacy notice or our practices with regard to your personal
                    information, please contact us at info@mesoproduce.com.
                </p>
                <p>
                    This privacy notice describes how we might use your
                    information if you:
                    <ul>
                        <li>
                            Download and use our mobile application — Meso
                            Produce
                        </li>
                        <li>
                            Engage with us in other related ways ― including any
                            sales, marketing, or events
                        </li>
                    </ul>
                    In this privacy notice, if we refer to:{' '}
                    <ul>
                        <li>
                            "<span style={{marginRight: 0}}>App</span>," we are
                            referring to any application of ours that references
                            or links to this policy, including any listed above
                        </li>
                        <li>
                            "<span style={{marginRight: 0}}>Services</span>," we
                            are referring to our App, and other related
                            services, including any sales, marketing, or events
                        </li>
                    </ul>
                    The purpose of this privacy notice is to explain to you in
                    the clearest way possible what information we collect, how
                    we use it, and what rights you have in relation to it. If
                    there are any terms in this privacy notice that you do not
                    agree with, please discontinue use of our Services
                    immediately.
                </p>
                <p>
                    <span>
                        Please read this privacy notice carefully, as it will
                        help you understand what we do with the information that
                        we collect.
                    </span>
                </p>
            </div>

            <div>
                <h2>Table of Contents</h2>
                <ul>
                    {content.map((c, i) => (
                        <li key={c.id} style={{textTransform: 'uppercase'}}>
                            <a href={`#${c.id}`}>{c.title}</a>
                        </li>
                    ))}
                </ul>
            </div>

            {content.map((c, i) => (
                <div id={c.id} key={c.id}>
                    <h2 className="pp-title">
                        {i + 1}. {c.title}
                    </h2>
                    {c.content.map((c2, i2) => (
                        <div key={`c${i2}`}>
                            {c2.title && <h3>{c2.title}</h3>}
                            {c2.inShort && (
                                <div className="pp-inShort">
                                    <span className="pp-bold">In Short:</span>
                                    {c2.inShort}
                                </div>
                            )}
                            {c2.p.map((line, i) => (
                                <div key={`p${i}`}>{line}</div>
                            ))}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}

export default PrivacyPolicy;
