import React from 'react';
import './components.css';
import PageTemplate from './PageTemplate';

function AppMissing(props) {
    const section1 = {
        tagline: '',
        content: [
            {
                title: 'Could not open the Meso app on this device.',
                body: <p></p>,
            },
            {
                title: 'If problem persists, try reinstalling the app.',
                body: <p></p>,
            },
        ],
    };
    const imageArray = [];
    const templateProps = {section1, imageArray};

    return <PageTemplate {...templateProps} />;
}

export default AppMissing;
