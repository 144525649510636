import React from 'react';
import './components.css';
import PageTemplate from './PageTemplate';

function Contact(props) {
    const section1 = {
        tagline: 'We want to hear from you',
        content: [
            {
                title: (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            flexWrap: 'wrap',

                            // wordBreak: 'break-word',
                        }}>
                        <div>info</div>
                        <div>@mesoproduce.com</div>
                        <br />
                        <br />
                    </div>
                ),
            },
            {
                title: 'Questions or Comments',
                body: (
                    <p>
                        Send us an email with any additional questions, we'll
                        respond promptly.
                    </p>
                ),
            },
            {
                title: 'Are you a market organizer or vendor?',
                body: (
                    <p>
                        Reach out and let us show you how the Meso platform
                        operates 24/7 to build your farmers' sales and expand
                        market access for your commercial and institutional
                        customers.
                    </p>
                ),
            },
        ],
    };
    const imageArray = [];
    const templateProps = {section1, imageArray};

    return <PageTemplate {...templateProps} />;
}

export default Contact;
