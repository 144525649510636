import React, {useEffect, useState, useRef} from 'react';
import {useLocation, Switch, Route, NavLink, Link} from 'react-router-dom';
// import logo from './imgs/logoRed_textWhite.png';

import logoIcon from './imgs/logo.png';
import letterhead from './imgs/letterhead_white.png';
import './App.css';
import Contact from './components/Contact';
import Landing from './components/Landing';
import About from './components/About';
import MenuIcon from '@material-ui/icons/Menu';
import PrivacyPolicy from './components/PrivacyPolicy';

import AppMissing from './components/AppMissing';
import {Helmet} from 'react-helmet-async';
import HowToOrder from './components/HowToOrder';
import Faq from './components/Faq';
import Partners from './components/Partners';

export const navItems = [
    {id: 'home', title: 'Home', route: '/home'},
    {id: 'about', title: 'About', route: '/about'},
    {
        id: 'partners',
        title: 'Farmers/\nPartners',
        route: '/farmers-partners',
    },
    {id: 'how_to_order', title: 'How to\nOrder', route: '/how-to-order'},
    {id: 'faq', title: 'FAQ', route: '/faq'},
    {id: 'contact', title: 'Contact Us', route: '/contact'},
];

function App() {
    const {pathname} = useLocation();
    const scrollContainer = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {
        setIsOpen((prevState) => !prevState);
    };

    useEffect(() => {
        setIsOpen(false);
        scrollContainer.current.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className="app-main">
            <Helmet>
                <meta charset="utf-8" />
                <title>Meso Produce</title>
                <meta
                    name="description"
                    content="Commercial distribution for market farmers"
                />
                <meta property="og:title" content="Meso Produce" />

                <meta
                    property="og:description"
                    content="Commercial distribution for market farmers"
                />
            </Helmet>
            <div className="navHeader">
                <a className="navHeader__logoContainer" href="/#hero">
                    <>
                        <img
                            id="headerLogo"
                            src={logoIcon}
                            alt="meso logo"
                            height="75%"
                        />
                        <img
                            id="headerLetterhead"
                            src={letterhead}
                            alt="meso letterhead"
                            height="50%"
                            style={{maxHeight: '100%'}}
                        />
                    </>
                </a>
                <div id="navMenu">
                    {navItems.map((e) => {
                        return (
                            <NavLink
                                key={e.id}
                                className="navLink linkText"
                                to={e.route}>
                                {e.title}
                            </NavLink>
                        );
                    })}
                    <a
                        className="navLink linkText"
                        rel="noreferrer"
                        target="_blank"
                        href="https://meso-produce.square.site/">
                        {'Merch'}
                    </a>
                </div>
                <div onClick={toggleMenu} className="navBurger">
                    <MenuIcon style={{fontSize: 36}} />
                </div>

                {/* backgroundColor: 'var(--mesoBlack)',
        position: 'absolute',
        top: 'var(--navHeight)',
        right: 0,
        zIndex: 10,
        paddingLeft: 16,
        paddingRight: 16,
        textAlign: 'right', */}

                {isOpen && <div id="menuModal" onClick={toggleMenu} />}

                <div
                    id="menuPane"
                    style={isOpen ? {right: '0', transition: '.25s'} : {}}
                    onClick={(e) => e.stopPropagation()}>
                    {navItems.map((e) => {
                        return (
                            <NavLink
                                key={e.id}
                                className="menuPaneLink linkText"
                                to={e.route}>
                                {e.title}
                            </NavLink>
                        );
                    })}
                    <a
                        className="menuPaneLink linkText"
                        rel="noreferrer"
                        target="_blank"
                        href="https://meso-produce.square.site/">
                        {'Merch'}
                    </a>
                </div>
            </div>
            <div id="content-frame" ref={scrollContainer}>
                <div>
                    <Switch>
                        <Route
                            path="/privacy-policy"
                            component={PrivacyPolicy}
                        />
                        <Route path="/about" component={About} />
                        <Route path="/farmers-partners" component={Partners} />
                        <Route path="/how-to-order" component={HowToOrder} />
                        <Route path="/faq" component={Faq} />
                        <Route path="/contact" component={Contact} />
                        <Route path="/app-missing" component={AppMissing} />
                        <Route path="/" component={Landing} />
                    </Switch>
                </div>

                <footer>
                    <div className="footerText">
                        <div>&#169;&nbsp;2023&nbsp;Meso Produce, Inc.</div>
                        <div>All rights reserved</div>
                        <a
                            className="footerLink"
                            rel="noreferrer"
                            target="_blank"
                            href="/privacy-policy">
                            Privacy Policy
                        </a>
                    </div>

                    <div className="footerButtonRow">
                        <Link
                            className="footerButton footerButton1 linkText"
                            to="/about">
                            About Meso
                        </Link>
                        <Link
                            className="footerButton footerButton2 linkText"
                            to="/faq">
                            FAQ
                        </Link>
                        <Link
                            className="footerButton footerButton3 linkText"
                            to="/contact">
                            Contact Us
                        </Link>
                    </div>
                    {/* <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://apps.apple.com/us/app/meso-produce/id1582369954"
                    className="badge-link">
                    <AppleBadge
                        style={{
                            display: 'block',
                            width: 'auto',
                        }}
                    />
                </a>
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://play.google.com/store/apps/details?id=com.mesonative"
                    className="badge-link">
                    <img
                        style={{display: 'block'}}
                        id="googleBadge"
                        src={googleBadge}
                        alt="google badge"
                        height="40px"
                    />
                </a> */}
                </footer>
            </div>
        </div>
    );
}

export default App;
