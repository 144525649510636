import React from 'react';
import PageTemplate from './PageTemplate';
import image1 from '../imgs/partner1.png';
import image2 from '../imgs/partner2.png';

function Partners(props) {
    const section1 = {
        tagline: "From the farmers' market\nto the chef's table",
        content: [
            {
                title: 'Built by farmers...',
                body: (
                    <>
                        <p>
                            Meso provides its distribution service at no charge
                            to our farmers.{'  '}We present your product
                            alongside that of our other producers to our
                            commercial customers, who are free to browse and
                            order instantly from Meso's aggregated offering via
                            the Meso app.
                            {'  '}
                            Customers can thus interface with Meso as a single
                            consolidated vendor operating on behalf of multiple
                            farmers, rather than the placing multiple individual
                            orders plus the corresponding transactions and
                            redundant deliveries.
                        </p>
                        <p>
                            The farmer is also freed from manually fielding and
                            processing orders from multiple customers.{'  '}
                            Product orders are consolidated automatically for
                            you to easily fulfill by bringing the summary
                            quantities to your next market, where we pickup,
                            sort and deliver via one consolidated route through
                            the city.
                        </p>
                        <p>
                            We charge the customer at every dropoff and disburse
                            the payment to you that same evening, automatically.
                        </p>
                    </>
                ),
            },
            {
                title: 'To forge a stronger market',
                body: (
                    <>
                        <p>
                            Beyond just farmers, we are also experienced market
                            organizers who understand the qualities that make
                            for vibrant market spaces and the immeasurable value
                            they provide.{'  '}At the end of the day, Meso is
                            only as strong as the markets it supports, and that
                            is by design.
                        </p>
                        <p>
                            We can work with you to tailor the platform to your
                            specific market dynamics.{'  '}Check out our
                            existing use cases below to better understand how.
                        </p>
                    </>
                ),
            },
        ],
        button: {
            title: 'See Use Cases',
            path: '#use-cases',
            className: 'button-black',
        },
    };
    const imageArray = [
        {id: 1, url: image1},
        {id: 2, url: image2},
    ];
    const section2 = {
        preTitle: 'Market Use Cases',
        sectionId: 'use-cases',
        content: [
            {
                title: 'Full Service',
                subTitle: 'Platform and Fulfillment',
                body: (
                    <>
                        <p>
                            WASHINGTON, DC -- Meso Produce operates as a
                            full-line distributor to restaurant and other
                            commercial customers.{'  '}We work exclusively with
                            farmers through over a dozen farm markets we support
                            across the city, and act in parallel with market
                            organizers in bringing greater revenue and
                            commercial business through these spaces.
                        </p>
                        <p>
                            In this model, we run the distribution using the
                            Meso platform which we built from the ground up for
                            this purpose.{'  '}As such, we are responsible for
                            every aspect of fulfillment from market to delivery
                            and we collect a sales margin for the corresponding
                            operation.
                        </p>
                    </>
                ),
            },
            {
                title: 'Self-Managed',
                subTitle: 'Platform only',
                body: (
                    <>
                        <p>
                            PUERTO RICO -- Meso operates as stand-alone platform
                            for the Mercado Agrícola Natural de Ponce (MANP) to
                            run its own distribution business in support of its
                            farmer network and commercial customers throughout
                            the island.{'  '}We provide and maintain the very
                            same, highly-available sales and delivery software
                            we use ourselves in our own markets.
                        </p>
                        <p>
                            By implementing the Meso platform, MANP expands
                            commercial reach for their producers, increasing
                            farmer revenues while also tapping into an income
                            stream for the market operation itself.{'  '}In this
                            model, fulfillment responsibilities are assumed by
                            MANP.
                        </p>
                    </>
                ),
            },
        ],
        button: {title: 'Contact Us', path: '/contact'},
    };

    const templateProps = {section1, imageArray, section2};

    return <PageTemplate {...templateProps} />;
}

export default Partners;
