import React from 'react';
import founder1 from '../imgs/founder1.png';
import founder2 from '../imgs/founder2.jpeg';
import image1 from '../imgs/about1.png';
import image2 from '../imgs/about2.png';
// import about3 from '../imgs/about3.png';
import PageTemplate from './PageTemplate.js';

function About(props) {
    const section1 = {
        isCards: true,
        tagline: 'We are market farmers\nwho wanted better distro',
        content: [
            {
                title: 'Market-based',
                body: (
                    <>
                        <p>
                            City markets play a vital role in the regional
                            economy.{'  '}Our approach to distribution engages
                            these spaces for their commercial potential as hubs
                            of a decentralized supply chain.
                        </p>
                        <p>
                            Farmers and producers often drive product upwards of
                            3 hours each way to market, despite the fact that
                            market sales are never guaranteed.{'  '}Meso pushes
                            back against this uncertainty, providing sales
                            revenues for products ordered to a given market--
                            before the truck has even left the farm.
                        </p>
                        <p>
                            Rather than drawing the life out and away from these
                            spaces, Meso strengthens the economic incentive for
                            farmers to participate and brings a greater
                            commercial vitality to the city markets.
                        </p>
                    </>
                ),
            },
            {
                title: 'Proprietary Logistics',
                body: (
                    <>
                        <p>
                            Technology platforms in the farming and market space
                            are all too often characterizable as "a solution in
                            search of a problem".{'  '}As farmers ourselves, we
                            built Meso from the ground up to solve distribution
                            for our own market.{'  '}In doing so, we have
                            created a powerful tool for city markets everywhere.
                        </p>
                        <p>
                            The core complexity of providing a transaction and
                            logistics framework for a network of farmers and
                            commercial customers across the city is no simple
                            matter.{'  '}Years of development have gone into
                            simplifying and streamlining this complexity for our
                            users, resulting in a beautiful and elegant product
                            that is the Meso platform.
                        </p>
                        <p>
                            We can be confident in the quality of what we've
                            built, because we use it ourselves everyday.
                        </p>
                    </>
                ),
            },
        ],
        button: {
            title: 'Get Started',
            path: '/how-to-order',
        },
    };

    const imageArray = [
        {id: 1, url: image1},
        {id: 2, url: image2},
        // {id: 3, url: about3},
    ];

    const section3 = {
        title: 'Meso founding team',
        content: [
            {
                name: 'Robert Fernández Coffey',
                imgSrc: founder1,
                bio: 'some bio here with info etc...',
                alt: 'Robert Fernandez Coffey',
            },
            {
                name: 'Richard Hunter',
                imgSrc: founder2,
                bio: 'some bio here with info etc...',
                alt: '',
            },
        ].map((f) => (
            <div key={f.name} className="founder-card">
                <img
                    className="founder-image"
                    src={f.imgSrc}
                    alt={f.alt}
                    height="225px"
                />
                <h2 className="founder-cardTitle">{f.name}</h2>
                {/* <div>{f.bio}</div> */}
            </div>
        )),
    };

    const templateProps = {
        section1,
        imageArray,
        section3,
    };

    return <PageTemplate {...templateProps} />;
}

export default About;
