import React from 'react';
import {Helmet} from 'react-helmet-async';
import Button from './Button';
import './About.css';
import './PageTemplate.css';
import {navItems} from '../App';
import {useLocation} from 'react-router-dom';

function PageTemplate(props) {
    const {pathname} = useLocation();
    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="Commercial distribution for market farmers"
                />
                <meta
                    property="og:description"
                    content="Commercial distribution for market farmers"
                />
            </Helmet>
            <div id="hero" className="landingHero nonLandingHero">
                <h1 className="tagline mobile-tagline">
                    {navItems.find((t) => t.route === pathname)?.title}
                </h1>
            </div>
            <div className="section-window">
                <h1 className="tagline mobile-hide">
                    {props.section1?.tagline}
                </h1>
                <div
                    className={
                        props.section1?.isCards
                            ? 'page-section1-contentCards'
                            : 'page-section1-content'
                    }>
                    {props.section1?.content.map((e) => (
                        <div
                            key={e.title}
                            className={
                                props.section1?.isCards
                                    ? 'page-section1-contentCardItem'
                                    : 'page-section1-contentItem'
                            }>
                            <h2 className="page-section1-itemTitle">
                                {e.title}
                            </h2>
                            <div
                                className="
                            page-section1-itemBody
                            body-text
                            body-italic
                            ">
                                {e.body}
                            </div>
                            {e.button && (
                                <div className="page-section1-buttonContainer">
                                    <Button
                                        linkTo={e.button.path}
                                        buttonClass="landingButton"
                                        titleClass="linkText"
                                        title={e.button.title || 'Get Started'}
                                    />
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                {props.section1?.button && (
                    <div className="page-section1-buttonContainer">
                        <Button
                            linkTo={props.section1.button.path}
                            buttonClass={
                                'landingButton ' +
                                (props.section1.button.className || '')
                            }
                            titleClass="linkText"
                            title={props.section1.button.title || 'Get Started'}
                        />
                    </div>
                )}
            </div>
            {props.imageArray?.length && (
                <div className="page-imageRow">
                    <div className="page-imagesContainer">
                        {props.imageArray.map((f) => (
                            <div
                                key={f.id}
                                style={{
                                    backgroundImage: `url(${f.url})`,
                                    width: `calc(100%/${props.imageArray.length})`,
                                }}
                                className="page-image"
                            />
                        ))}
                    </div>
                </div>
            )}
            {props.section2 && (
                <div
                    className="section-window section-window-dark"
                    id={props.section2.sectionId || ''}>
                    <div className="separatorComponentBlack" />
                    {/* <div className="page-section2-content"> */}
                    <h3 className="section-title section-title-medium page-section2-preTitle">
                        {props.section2?.preTitle}
                    </h3>
                    <div className="page-section2-content">
                        {props.section2?.content?.map((f) => (
                            <div
                                key={f.title}
                                className="page-section2-contentHalf">
                                <h2 className="page-section2-title">
                                    {f.title}
                                </h2>
                                <h2 className="page-section2-title page-section2-subTitle">
                                    {f.subTitle}
                                </h2>
                                <div
                                    className="page-section2-body body-text 
                                body-italic">
                                    {f.body}
                                </div>
                            </div>
                        ))}
                    </div>
                    {props.section2?.button && (
                        <div className="page-section2-buttonContainer">
                            <Button
                                linkTo={
                                    props.section2.button.path ||
                                    '/how-to-order'
                                }
                                buttonClass="landingButton"
                                titleClass="linkText"
                                title={
                                    props.section2.button.title || 'Get Started'
                                }
                            />
                        </div>
                    )}
                </div>
            )}
            {props.section3 && (
                <div className="page-section3-window">
                    <div className="separatorComponentBlack" />
                    <h3 className="section-title page-section3-title">
                        {props.section3?.title}
                    </h3>
                    <div className="page-section3-content">
                        {props.section3?.content}
                    </div>
                </div>
            )}
        </>
    );
}

export default PageTemplate;
