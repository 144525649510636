import React from 'react';
import PageTemplate from './PageTemplate';
import image1 from '../imgs/faq1.png';
import image2 from '../imgs/faq2.png';
// import faq3 from '../imgs/faq3.png';

function Faq(props) {
    const section1 = {
        tagline: 'Common Questions',
        content: [
            {
                title: 'What types of products do you sell?',
                body: (
                    <p>
                        We work with farmers and producers of all types to offer
                        their products at wholesale price points direct to
                        commercial customers.{'  '}That includes meat, poultry,
                        dairy, grains, produce, seafood and a variety of dry
                        goods as well.{'  '}In all cases, we work exclusively
                        with the producers themselves and do not offer reseller
                        or third-party items.{'  '}Feel free to create an
                        account and browse what is in market.
                    </p>
                ),
            },
            {
                title: 'Do you only work with market farmers?',
                body: (
                    <p>
                        Yes.{'  '}Our entire approach and logistics platform
                        centers around the markets in the city. City markets are
                        vital economic spaces that gather the region’s top
                        quality producers together.{'  '}They also present the
                        advantage of proximity to the city’s commercial
                        customers, by which Meso is able to offer an emminently
                        superior product in quality, price and freshness.
                    </p>
                ),
            },
            {
                title: 'Is there desktop version of the app?',
                body: (
                    <p>
                        Not quite, although newer Mac computers can run the
                        mobile app on desktop;{'  '}it's not what we designed
                        for but can work just fine.
                    </p>
                ),
            },
            {title: 'Is there a minimum order?', body: <p>No.</p>},
            {
                title: 'Is there a limit to how many orders I can place?',
                body: (
                    <p>
                        No.{'  '}Orders can be placed on a continuous basis and
                        will self-organize into deliveries based on next market
                        day for those products.
                    </p>
                ),
            },
            {
                title: 'What days does Meso deliver?',
                body: (
                    <p>
                        Products are delivered according to their market day.
                        {'  '}This means you receive the freshest possible
                        product, on average just 3-4 hours after leaving the
                        farm where it was produced.{'  '}Compare this to other
                        distributors who maintain their inventory in centralized
                        warehouses, often stored weeks at a time before a sale.
                        {'  '}Meso is a different paradigm of quality, and this
                        will be immediately apparent to our first time
                        customers.
                    </p>
                ),
            },
            {
                title: 'Is there an order cutoff time for products?',
                body: (
                    <p>
                        Order cutoff for a particular market day is noon of the
                        day before, after which a product will update to reflect
                        its next available market delivery day.
                    </p>
                ),
            },
            {
                title: 'Do you offer customers Net 30 terms for Payment?',
                body: (
                    <p>
                        We do not offer Net 30 terms on deliveries.{'  '}Our
                        approach is straightforward and reliable:{'  '}Meso
                        processes the customer charge automatically following
                        each delivery for exactly the goods that were delivered.
                        {'  '}The payment is then disbursed that evening to the
                        farmer.
                    </p>
                ),
            },
            {
                title: 'As a vendor, when do I get paid?',
                body: (
                    <p>
                        Payments to vendors are processed automatically at 9pm
                        of the same day that goods are fulfilled.
                    </p>
                ),
            },
        ],
    };
    const imageArray = [
        {id: 1, url: image1},
        {id: 2, url: image2},
        // {id: 3, url: faq1},
    ];
    const templateProps = {
        section1,
        imageArray,
    };

    return <PageTemplate {...templateProps} />;
}

export default Faq;
