export const content = [
    {
        id: 'infocollect',
        title: 'What information do we collect?',
        content: [
            {
                title: 'Personal information you disclose to us',
                inShort:
                    'We collect personal information that you provide to us.',
                p: [
                    <p>
                        We collect personal information that you voluntarily
                        provide to us when you register on the App, express an
                        interest in obtaining information about us or our
                        products and Services, when you participate in
                        activities on the App or otherwise when you contact us.
                    </p>,
                    <p>
                        The personal information that we collect depends on the
                        context of your interactions with us and the App, the
                        choices you make and the products and features you use.
                        The personal information we collect may include the
                        following:
                    </p>,
                    <p>
                        <span>Personal Information Provided by You.</span> We
                        collect names; phone numbers; email addresses; billing
                        addresses; passwords; contact or authentication data;
                        debit/credit card numbers; and other similar
                        information.
                    </p>,
                    <p>
                        <span>Payment Data.</span> We may collect data necessary
                        to process your payment if you make purchases, such as
                        your payment instrument number (such as a credit card
                        number), and the security code associated with your
                        payment instrument. All payment data is stored by
                        Stripe. You may find their privacy notice link(s) here:
                        https://stripe.com/privacy.
                    </p>,
                    <p>
                        All personal information that you provide to us must be
                        true, complete and accurate, and you must notify us of
                        any changes to such personal information.
                    </p>,
                ],
            },
            {
                title: 'Information collected through our App',
                inShort:
                    'We collect information regarding your push notifications, when you use our App',
                p: [
                    <p>
                        If you use our App, we also collect the following
                        information:
                    </p>,
                    <p>
                        <span>Push Notifications.</span> We may request to send
                        you push notifications regarding your account or certain
                        features of the App. If you wish to opt-out from
                        receiving these types of communications, you may turn
                        them off in your device's settings.
                    </p>,
                    <p>
                        This information is primarily needed to maintain the
                        security and operation of our App, for troubleshooting
                        and for our internal analytics and reporting purposes.
                    </p>,
                ],
            },
        ],
    },
    {
        id: 'infouse',
        title: 'How do we use your information?',
        content: [
            {
                inShort:
                    'We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.',
                p: [
                    <p>
                        We use personal information collected via our App for a
                        variety of business purposes described below. We process
                        your personal information for these purposes in reliance
                        on our legitimate business interests, in order to enter
                        into or perform a contract with you, with your consent,
                        and/or for compliance with our legal obligations. We
                        indicate the specific processing grounds we rely on next
                        to each purpose listed below.
                    </p>,
                    <div>
                        <p>We use the information we collect or receive:</p>
                        <ul>
                            <li>
                                <span>
                                    To facilitate account creation and logon
                                    process.
                                </span>
                                If you choose to link your account with us to a
                                third-party account (such as your Google or
                                Facebook account), we use the information you
                                allowed us to collect from those third parties
                                to facilitate account creation and logon process
                                for the performance of the contract.
                            </li>
                            <li>
                                <span>To post testimonials.</span>
                                We post testimonials on our App that may contain
                                personal information. Prior to posting a
                                testimonial, we will obtain your consent to use
                                your name and the content of the testimonial. If
                                you wish to update, or delete your testimonial,
                                please contact us at info@mesoproduce.com and be
                                sure to include your name, testimonial location,
                                and contact information.
                            </li>
                            <li>
                                <span>Request feedback.</span>
                                We may use your information to request feedback
                                and to contact you about your use of our App.
                            </li>
                            <li>
                                <span>
                                    To enable user-to-user communications.
                                </span>
                                We may use your information in order to enable
                                user-to-user communications with each user's
                                consent.
                            </li>
                            <li>
                                <span>To manage user accounts.</span>
                                We may use your information for the purposes of
                                managing our account and keeping it in working
                                order.
                            </li>
                            <li>
                                <span>Fulfill and manage your orders.</span>
                                We may use your information to fulfill and
                                manage your orders, payments, returns, and
                                exchanges made through the App.
                            </li>
                            <li>
                                <span>
                                    Administer prize draws and competitions.
                                </span>
                                We may use your information to administer prize
                                draws and competitions when you elect to
                                participate in our competitions.
                            </li>
                            <li>
                                <span>
                                    To deliver and facilitate delivery of
                                    services to the user.
                                </span>
                                We may use your information to provide you with
                                the requested service.
                            </li>
                            <li>
                                <span>
                                    To respond to user inquiries/offer support
                                    to users.
                                </span>
                                We may use your information to respond to your
                                inquiries and solve any potential issues you
                                might have with the use of our Services.
                            </li>
                        </ul>
                    </div>,
                ],
            },
        ],
    },
    {
        id: 'infoshare',
        title: 'Will your information be shared with anyone?',
        content: [
            {
                inShort:
                    'We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.',
                p: [
                    <div>
                        <p>
                            We may process or share your data that we hold based
                            on the following legal basis:
                        </p>
                        <ul>
                            <li>
                                <span>Consent:</span>We may process your data if
                                you have given us specific consent to use your
                                personal information for a specific purpose.
                            </li>
                            <li>
                                <span>Legitimate Interests:</span> We may
                                process your data when it is reasonably
                                necessary to achieve our legitimate business
                                interests.
                            </li>
                            <li>
                                <span>Performance of a Contract:</span>Where we
                                have entered into a contract with you, we may
                                process your personal information to fulfill the
                                terms of our contract.
                            </li>
                            <li>
                                <span>Legal Obligations:</span>We may disclose
                                your information where we are legally required
                                to do so in order to comply with applicable law,
                                governmental requests, a judicial proceeding,
                                court order, or legal process, such as in
                                response to a court order or a subpoena
                                (including in response to public authorities to
                                meet national security or law enforcement
                                requirements).
                            </li>
                            <li>
                                <span>Vital Interests:</span>We may disclose
                                your information where we believe it is
                                necessary to investigate, prevent, or take
                                action regarding potential violations of our
                                policies, suspected fraud, situations involving
                                potential threats to the safety of any person
                                and illegal activities, or as evidence in
                                litigation in which we are involved.
                            </li>
                        </ul>
                    </div>,
                    <div>
                        <p>
                            More specifically, we may need to process your data
                            or share your personal information in the following
                            situations:
                        </p>
                        <ul>
                            <li>
                                <span>Business Transfers.</span>We may share or
                                transfer your information in connection with, or
                                during negotiations of, any merger, sale of
                                company assets, financing, or acquisition of all
                                or a portion of our business to another company.
                            </li>
                            <li>
                                <span>
                                    Vendors, Consultants and Other Third-Party
                                    Service Providers.
                                </span>
                                We may share your data with third-party vendors,
                                service providers, contractors or agents who
                                perform services for us or on our behalf and
                                require access to such information to do that
                                work. Examples include: payment processing, data
                                analysis, email delivery, hosting services,
                                customer service and marketing efforts. We may
                                allow selected third parties to use tracking
                                technology on the App, which will enable them to
                                collect data on our behalf about how you
                                interact with our App over time. This
                                information may be used to, among other things,
                                analyze and track data, determine the popularity
                                of certain content, pages or features, and
                                better understand online activity. Unless
                                described in this notice, we do not share, sell,
                                rent or trade any of your information with third
                                parties for their promotional purposes.
                            </li>
                        </ul>
                    </div>,
                ],
            },
        ],
    },
    {
        id: 'whoshare',
        title: 'Who will your information be shared with?',
        content: [
            {
                inShort:
                    'We only share information with the following categories of third parties.',
                p: [
                    <div>
                        <p>
                            We only share and disclose your information with the
                            following categories of third parties. If we have
                            processed your data based on your consent and you
                            wish to revoke your consent, please contact us using
                            the contact details provided in the section below
                            titled
                            <a href="#contact" style={{marginLeft: '.5rem'}}>
                                "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
                            </a>
                            .
                        </p>
                        <ul>
                            <li>Cloud Computing Services</li>
                            <li>Payment Processors</li>
                        </ul>
                    </div>,
                ],
            },
        ],
    },
    {
        id: 'infosafe',
        title: 'How do we keep your information safe?',
        content: [
            {
                inShort:
                    'We aim to protect your personal information through a system of organizational and technical security measures.',
                p: [
                    <p>
                        We have implemented appropriate technical and
                        organizational security measures designed to protect the
                        security of any personal information we process.
                        However, despite our safeguards and efforts to secure
                        your information, no electronic transmission over the
                        Internet or information storage technology can be
                        guaranteed to be 100% secure, so we cannot promise or
                        guarantee that hackers, cybercriminals, or other
                        unauthorized third parties will not be able to defeat
                        our security, and improperly collect, access, steal, or
                        modify your information. Although we will do our best to
                        protect your personal information, transmission of
                        personal information to and from our App is at your own
                        risk. You should only access the App within a secure
                        environment.
                    </p>,
                ],
            },
        ],
    },
    {
        id: 'privacyrights',
        title: 'What are your privacy rights?',
        content: [
            {
                inShort:
                    'You may review, change, or terminate your account at any time',
                p: [
                    <p>
                        If you are a resident in the EEA or UK and you believe
                        we are unlawfully processing your personal information,
                        you also have the right to complain to your local data
                        protection supervisory authority. You can find their
                        contact details here:
                        https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
                    </p>,
                    <p>
                        If you are a resident in Switzerland, the contact
                        details for the data protection authorities are
                        available here:
                        https://www.edoeb.admin.ch/edoeb/en/home.html.
                    </p>,
                    <p>
                        If you have questions or comments about your privacy
                        rights, you may email us at info@mesoproduce.com.
                    </p>,
                ],
            },
            {
                title: 'Account Information',
                p: [
                    <div>
                        <p>
                            If you would at any time like to review or change
                            the information in your account or terminate your
                            account, you can:
                        </p>
                        <ul>
                            <li>
                                Contact us using the contact information
                                provided.
                            </li>
                            <li>
                                Log in to your account settings and update your
                                user account.
                            </li>
                        </ul>
                    </div>,
                    <p>
                        Upon your request to terminate your account, we will
                        deactivate or delete your account and information from
                        our active databases. However, we may retain some
                        information in our files to prevent fraud, troubleshoot
                        problems, assist with any investigations, enforce our
                        Terms of Use and/or comply with applicable legal
                        requirements.
                    </p>,
                    <div>
                        <p>
                            <span style={{textDecoration: 'underline'}}>
                                Opting out of email marketing:
                            </span>
                            You can unsubscribe from our marketing email list at
                            any time by clicking on the unsubscribe link in the
                            emails that we send or by contacting us using the
                            details provided below. You will then be removed
                            from the marketing email list — however, we may
                            still communicate with you, for example to send you
                            service-related emails that are necessary for the
                            administration and use of your account, to respond
                            to service requests, or for other non-marketing
                            purposes. To otherwise opt-out, you may:
                        </p>
                        <ul>
                            <li>
                                Contact us using the contact information
                                provided.
                            </li>
                            <li>
                                Access your account settings and update your
                                preferences.
                            </li>
                        </ul>
                    </div>,
                ],
            },
        ],
    },
    {
        id: '#donottrack',
        title: 'Controls for do-not-track features',
        content: [
            {
                p: [
                    <p>
                        Most web browsers and some mobile operating systems and
                        mobile applications include a Do-Not-Track ("DNT")
                        feature or setting you can activate to signal your
                        privacy preference not to have data about your online
                        browsing activities monitored and collected. At this
                        stage no uniform technology standard for recognizing and
                        implementing DNT signals has been finalized. As such, we
                        do not currently respond to DNT browser signals or any
                        other mechanism that automatically communicates your
                        choice not to be tracked online. If a standard for
                        online tracking is adopted that we must follow in the
                        future, we will inform you about that practice in a
                        revised version of this privacy notice.
                    </p>,
                ],
            },
        ],
    },
    {
        id: 'caresidents',
        title: 'Do California residents have specific privacy rights?',
        content: [
            {
                inShort:
                    'Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.',
                p: [
                    <p>
                        California Civil Code Section 1798.83, also known as the
                        "Shine The Light" law, permits our users who are
                        California residents to request and obtain from us, once
                        a year and free of charge, information about categories
                        of personal information (if any) we disclosed to third
                        parties for direct marketing purposes and the names and
                        addresses of all third parties with which we shared
                        personal information in the immediately preceding
                        calendar year. If you are a California resident and
                        would like to make such a request, please submit your
                        request in writing to us using the contact information
                        provided below.
                    </p>,
                    <p>
                        If you are under 18 years of age, reside in California,
                        and have a registered account with the App, you have the
                        right to request removal of unwanted data that you
                        publicly post on the App. To request removal of such
                        data, please contact us using the contact information
                        provided below, and include the email address associated
                        with your account and a statement that you reside in
                        California. We will make sure the data is not publicly
                        displayed on the App, but please be aware that the data
                        may not be completely or comprehensively removed from
                        all our systems (e.g. backups, etc.).
                    </p>,
                ],
            },
        ],
    },
    {
        id: 'policyupdates',
        title: 'Do we make updates to this notice?',
        content: [
            {
                inShort:
                    'Yes, we will update this notice as necessary to stay compliant with relevant laws.',
                p: [
                    <p>
                        We may update this privacy notice from time to time. The
                        updated version will be indicated by an updated
                        "Revised" date and the updated version will be effective
                        as soon as it is accessible. If we make material changes
                        to this privacy notice, we may notify you either by
                        prominently posting a notice of such changes or by
                        directly sending you a notification. We encourage you to
                        review this privacy notice frequently to be informed of
                        how we are protecting your information.
                    </p>,
                ],
            },
        ],
    },
    {
        id: 'contact',
        title: 'How can you contact us about this notice?',
        content: [
            {
                p: [
                    <p>
                        If you have questions or comments about this notice, you
                        may email us at info@mesoproduce.com
                    </p>,
                ],
            },
        ],
    },
    {
        id: 'request',
        title: 'How can you review, update or delete the data we collect from you?',
        content: [
            {
                p: [
                    <p>
                        Based on the applicable laws of your country, you may
                        have the right to request access to the personal
                        information we collect from you, change that
                        information, or delete it in some circumstances. To
                        request to review, update, or delete your personal
                        information, please email us at info@mesoproduce.com.
                    </p>,
                ],
            },
        ],
    },
];
