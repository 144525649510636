import React from 'react';
import './Landing.css';
import {Helmet} from 'react-helmet-async';
import Button from './Button';

const landingCards = [
    {
        pretitle: '24/7',
        title: 'Availability',
        body: 'Place your orders any time of the day or week from the aggregated product list via the Meso platform.  Meso receives, organizes and communicates incoming orders for farmers, making for easy, structured fulfillment to market.',
        // cardFooter: 'Automate the boring stuff',
        // button: {},
    },
    {
        pretitle: 'No fees',
        title: 'No Minimum',
        body: 'There is no minimum order for commercial delivery, no delivery charge or additional fees of any kind.  The published prices are what the customer pays and nothing more.  Plain and simple.',
        // cardFooter: 'Clean and simple',
    },
    {
        pretitle: 'Unmatched',
        title: 'Quality',
        body: 'Meso delivers directly from the market to the customer, which means product is arriving to you a mere hours following harvest.  Compare that to other distributors who operate as resellers, warehousing product for days or often weeks before sale.',
        // cardFooter: 'A truer product',
    },

    // {
    //     pretitle: 'Real-time',
    //     title: 'Pricing',
    //     body: 'Our prices are always live.  to account for specific product characteristics and delivery distance. You will not find a more competitive pricing structure from any other distributor.',
    //     button: {},
    // },
];

function Landing(props) {
    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="Commercial distribution for market farmers"
                />
                <meta
                    property="og:description"
                    content="Commercial distribution for market farmers"
                />
            </Helmet>
            <div id="hero" className="landingHero">
                <div className="landingHeroOverlay">
                    <div className="landingHeroPreTitle">
                        Bridging the Regional Industry
                    </div>
                    <h1 className="tagline landingTagline">
                        Commercial distribution
                        <br />
                        for market farms
                    </h1>
                    <div className="landingHeroSubTitle">
                        Currently serving markets in Washington, DC and Puerto
                        Rico
                    </div>

                    <div className="landingButtonContainer">
                        <Button
                            linkTo="/how-to-order"
                            buttonClass="landingButton"
                            titleClass="linkText"
                            title="Get Started"
                        />
                    </div>
                </div>
            </div>

            <div className="section-window">
                <div className="separatorComponent" />
                <div className="landingCardsContainer">
                    {landingCards.map((card, i) => (
                        <div key={i} className="landingCard">
                            <div className="landingCardTitleContainer">
                                {card.pretitle && (
                                    <h3 className="section-title landingCardPreTitle">
                                        {card.pretitle}
                                    </h3>
                                )}
                                <h2 className="section-title">{card.title}</h2>
                            </div>
                            <div className="landingCardBody body-text body-italic">
                                {card.body}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="section1-buttonContainer">
                    <Button
                        linkTo="/about"
                        buttonClass="landingButton button-black"
                        titleClass="linkText"
                        title="Learn More"
                    />
                    {/* <Button
                        linkTo="/how-to-order"
                        buttonClass="landingButton"
                        titleClass="linkText"
                        title="Get Started"
                    /> */}
                </div>
            </div>
            <div className="section-window section-window-dark">
                <div className="separatorComponentBlack" />
                <h3 className="section-title section-title-medium page-section2-preTitle">
                    Our Service
                </h3>
                <div className="page-section2-content">
                    {[
                        {
                            title: 'For Farmers',
                            subTitle: 'Distribution as a Service',
                            body: (
                                <>
                                    <p>
                                        Meso builds upon your existing market
                                        business, adding a commercial sales and
                                        distribution component with no
                                        additional operational expense.{'  '}
                                        Direct your customers to place their
                                        orders via the Meso app, and we'll
                                        handle the tracking, fulfillment,
                                        payments and delivery on your behalf.
                                    </p>
                                    <p>
                                        Meso acts as a single interface for all
                                        of your commercial sales and delivery
                                        channels.{'  '}Bring in market revenues
                                        24/7 before you've even loaded the
                                        truck.
                                    </p>
                                </>
                            ),
                            // button: {
                            //     title: 'Learn More',
                            //     path: '/',
                            //     className: 'button-white',
                            //     textClass: 'button-text-black',
                            // },
                        },
                        {
                            title: 'For Buyers',
                            subTitle: 'A single consolidated vendor',
                            body: (
                                <>
                                    <p>
                                        Meso streamlines the typical restaurant
                                        ordering workflow by providing a single
                                        vendor interface for restaurants who
                                        work with multiple regional producers.
                                        {'  '}Order 24/7 via the Meso app from
                                        any number of the vendors we support in
                                        one consolidated transaction and
                                        delivery.
                                    </p>
                                    <p>
                                        Don't let the burden of dealing with
                                        multiple vendors impact your ability to
                                        source the highest quality product from
                                        your region's farmers.{'  '}
                                        Let Meso handle the grunt work so you
                                        can focus on the work that matters.
                                    </p>
                                </>
                            ),
                            // button: {title: 'Order Now', path: '/how-to-order'},
                        },
                    ].map((f) => (
                        <div
                            className="page-section2-contentHalf"
                            key={f.title}>
                            <h2 className="page-section2-title">{f.title}</h2>
                            <h2 className="page-section2-title page-section2-subTitle">
                                {f.subTitle}
                            </h2>
                            <div className="page-section2-body body-text body-italic">
                                {f.body}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="page-section2-buttonContainer">
                    <Button
                        linkTo={'/how-to-order'}
                        buttonClass={'landingButton'}
                        titleClass={'linkText'}
                        title={'Get Started'}
                    />
                </div>
            </div>
        </>
    );
}

export default Landing;
