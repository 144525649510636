import React from 'react';
import './Button.css';

function Button(props) {
    return (
        <a className="mesoButtonLink" href={props.linkTo}>
            <div
                style={props.style}
                className={'mesoButton ' + props.buttonClass}>
                <div className={props.titleClass || ''}>{props.title}</div>
            </div>
        </a>
    );
}

export default Button;
