import React from 'react';
import PageTemplate from './PageTemplate.js';
import googleBadge from '../imgs/badge-google.png';
import {ReactComponent as AppleBadge} from '../imgs/appleBadge.svg';
import './HowToOrder.css';
import image1 from '../imgs/order1.png';
import image2 from '../imgs/order2.png';
// import order3 from '../imgs/order3.png';

function HowToOrder(props) {
    const section1 = {
        tagline: 'Get Started with\nMeso Produce',
        content: [
            {
                title: 'Download the mobile app',
                body: (
                    <div className="order-badges-container">
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://apps.apple.com/us/app/meso-produce/id1582369954"
                            className="order-badgeLink">
                            <AppleBadge style={{display: 'block'}} />
                        </a>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://play.google.com/store/apps/details?id=com.mesonative"
                            className="order-badgeLink">
                            <img
                                id="googleBadge"
                                style={{display: 'block'}}
                                src={googleBadge}
                                alt="google badge"
                                height="40px"
                            />
                        </a>
                    </div>
                ),
            },
            {
                title: 'Create a User Account',
                body: (
                    <>
                        <p>
                            Open the app and click the Create New User button
                            from the login screen. Follow the simple prompt to
                            include your name, email and password.
                        </p>
                        <p>
                            You’ll receive a confirmation email with a link,
                            which you’ll need to click on from the same device
                            that has the app installed. . Once your email has
                            been confirmed with us, you’ll be able to log in
                            using the email and password you just created.
                        </p>
                    </>
                ),
            },
            {
                title: "That's it",
                body: (
                    <>
                        <p>
                            You can now browse and order from the full line of
                            market products we offer from our producers. You’ll
                            need only a form of payment and a delivery address
                            to complete the checkout process. You can add these
                            from the menu in the top right.
                        </p>
                        <p>
                            Prices are shown in real-time and include all
                            applicable taxes and delivery fees. All products are
                            fulfilled on their next available market day, which
                            is displayed.
                        </p>
                        <p>
                            There is no minimum order or additional fees of any
                            kind.
                        </p>
                    </>
                ),
            },
        ],
    };

    const imageArray = [
        {id: 1, url: image1},
        {id: 2, url: image2},
        // {id: 3, url: order3},
    ];

    const templateProps = {section1, imageArray};

    return <PageTemplate {...templateProps} />;
}

export default HowToOrder;
